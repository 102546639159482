import * as React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import AlphaLogo from '../assets/svgs/umber-alpha.svg'

// styles
const Page = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000;
  height: 100%;
  width: 100%;
  color: #ffffff;
`

const Container = styled.div`
  display: block;
  float: right;
  width: 70%;
`

const Title = styled.h1`
  letter-spacing: -0.04em;
  font-size: 2.8em;
  line-height: 0.9em;
`

const Copy = styled.p`
  margin-top: 2em;
  margin-bottom: 0;
  letter-spacing: -0.02em;
  font-size: 1em;
  line-height: 1.3em;
`

const TitleContainer = styled.div`
  height: 100%;
  display: block;

  background: linear-gradient(
    89.41deg,
    #c0ddff 23.46%,
    #eabdf5 44.84%,
    #c4b1fa 69.44%,
    #fab1dd 79.69%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Float = styled.div`
  position: absolute;
  top: 2.5em;
  left: 2em;
`

// markup
const NotInvitedPage = () => {
  return (
    <Page>
      <Helmet>
        <title>not invited</title>
      </Helmet>
      <Float>
        <AlphaLogo />
      </Float>
      <Container>
        <TitleContainer>
          <Title>
            wrong
            <br />
            place, bud.
          </Title>
        </TitleContainer>
        <Copy>
          we don’t have you down for umber’s alpha program. if you think this is
          a mistake, please contact the team member who originally invited you.
        </Copy>
      </Container>
    </Page>
  )
}

export default NotInvitedPage
